import { TimeObject } from '@/Utils/timeTranslate';
import { InputNumber } from 'antd';
import React, { useEffect, useState } from 'react';

interface SkuTimestampProps {
  timeObject: TimeObject;
  skuIndex: number;
  timestampIndex: number;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject) => void;
}

const SkuTimestampEditor = ({
  skuIndex,
  timestampIndex,
  timeObject,
  onTimeObjectChange,
}: SkuTimestampProps) => {
  const [hour, setHour] = useState<string | null>('0');
  const [minute, setMinute] = useState<string | null>('0');
  const [second, setSecond] = useState<string | null>('0');

  const onHourChange = (value: string | null) => {
    setHour(value ?? '0');
    onTimeObjectChange(skuIndex, timestampIndex, { ...timeObject, hour: value ?? '0' });
  };

  const onMinuteChange = (value: string | null) => {
    setMinute(value ?? '0');
    onTimeObjectChange(skuIndex, timestampIndex, { ...timeObject, minute: value ?? '0' });
  };

  const onSecondChange = (value: string | null) => {
    setSecond(value ?? '0');
    onTimeObjectChange(skuIndex, timestampIndex, { ...timeObject, second: value ?? '0' });
  };

  useEffect(() => {
    if (timeObject) {
      setHour(timeObject.hour ?? '0');
      setMinute(timeObject.minute ?? '0');
      setSecond(timeObject.second ?? '0');
    }
  }, [timeObject]);

  return (
    <div className="flex-row">
      <InputNumber
        className="me-2 w-14"
        placeholder="00"
        value={hour}
        onChange={onHourChange}
        min="0"
        controls={false}
      />
      :
      <InputNumber
        className="ms-2 me-2 w-14"
        placeholder="00"
        value={minute}
        onChange={onMinuteChange}
        min="0"
        max="59"
        controls={false}
      />
      :
      <InputNumber
        className="ms-2 w-14"
        placeholder="00"
        value={second}
        onChange={onSecondChange}
        min="0"
        max="59"
        controls={false}
      />{' '}
      :
    </div>
  );
};

export default SkuTimestampEditor;
