/* eslint-disable import/prefer-default-export */
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import {
  getVodPost,
  setVodPost,
  getAllChannel,
  setAllChannel,
  setChannelCategory,
  postChannelPostSuccess,
  postChannelPost,
  deleteChannelPostSuccess,
  deleteChannelPost,
  setSelectedChannel,
  setSelectedChannelSuccess,
  createChannel,
  createChannelSuccess,
  updateChannels,
  updateChannelsSuccess,
  failure,
} from '@/Redux/slices/VodSlice';

import { fetchVodAPI } from '@/Axios/api/postManagement';
import { PostManagementParam } from '@/Interfaces/I_postManagement';
import {
  createChannelAPI,
  deleteChannelPostAPI,
  fetchAllChannelAPI,
  fetchChannelCategoryAPI,
  postChannelPostAPI,
  updateChannelAPI,
} from '@/Axios/api/channel';
import { message } from 'antd';
import {
  ChannelPostDataParam,
  CreateChannelRequest,
  UpdateChannelRequest,
} from '@/Interfaces/I_vod';

function* handleVodGetPost(action: PayloadAction<PostManagementParam>) {
  try {
    const data: AxiosResponse = yield call(fetchVodAPI, action.payload.params);
    yield put(setVodPost(data));
  } catch (e) {
    message.error({
      content: 'Fail to get vod post, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleGetAllChannel(_action: PayloadAction) {
  try {
    const data: AxiosResponse = yield call(fetchAllChannelAPI);
    yield put(setAllChannel(data));
  } catch (e) {
    message.error({
      content: 'Fail to get channels, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handlePostChannelPost(action: PayloadAction<ChannelPostDataParam>) {
  try {
    yield call(postChannelPostAPI, action.payload);
    yield put(postChannelPostSuccess(action.payload));
  } catch (e) {
    message.error({
      content: 'Fail to add post in channel, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleDeleteChannelPost(action: PayloadAction<ChannelPostDataParam>) {
  try {
    yield call(deleteChannelPostAPI, action.payload);
    yield put(deleteChannelPostSuccess(action.payload));
  } catch (e) {
    message.error({
      content: 'Fail to add post in channel, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleSetSelectedChannelAndGetCategory(action: PayloadAction<number>) {
  try {
    // set selected channel
    yield put(setSelectedChannelSuccess(action.payload));

    // api to get selected channel's category
    const data: AxiosResponse = yield call(fetchChannelCategoryAPI, action.payload);
    yield put(setChannelCategory(data));
  } catch (e) {
    message.error({
      content: 'Fail to get channel category, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
  }
}

function* handleCreateChannel(action: PayloadAction<CreateChannelRequest>) {
  try {
    const data: AxiosResponse = yield call(createChannelAPI, action.payload);
    yield put(createChannelSuccess(data));
  } catch (e) {
    message.error({
      content: 'Fail to create channel, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
    yield put(failure({}));
  }
}

function* handleUpdateChannels(action: PayloadAction<UpdateChannelRequest>) {
  try {
    const data: AxiosResponse = yield call(updateChannelAPI, action.payload);
    yield put(updateChannelsSuccess(data));
  } catch (e) {
    message.error({
      content: 'Fail to updated channels, please try again.',
      duration: 3,
      style: {
        marginTop: '120px',
      },
    });
    yield put(failure({}));
  }
}

export function* watchGetPost() {
  yield takeLatest(getVodPost.type, handleVodGetPost);
  yield takeLatest(getAllChannel.type, handleGetAllChannel);
  yield takeLatest(postChannelPost.type, handlePostChannelPost);
  yield takeLatest(deleteChannelPost.type, handleDeleteChannelPost);
  yield takeLatest(setSelectedChannel.type, handleSetSelectedChannelAndGetCategory);
  yield takeLatest(createChannel.type, handleCreateChannel);
  yield takeLatest(updateChannels.type, handleUpdateChannels);
}
