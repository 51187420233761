/* eslint-disable camelcase */
import {
  PatchPostCategoryParam,
  PinnedLaningRequest,
  PinnedExplorePostRequest,
  PostParm,
  UpdatePostDataParam,
} from '@/Interfaces/I_postManagement';

import { customAxios } from '../index';

// get categories
export const fetchCategoriesAPI = () =>
  customAxios({
    url: 'hktv_koc/cms/categories',
    method: 'GET',
  });

export const fetchMallCategoriesAPI = () =>
  customAxios({
    url: 'hktv_koc/cms/categories/mall',
    method: 'GET',
  });

// get post
export const fetchPostAPI = (data: PostParm) =>
  customAxios({
    url: 'hktv_koc/cms/posts',
    method: 'GET',
    params: data,
  });

// get vod post
export const fetchVodAPI = (data: PostParm) =>
  customAxios({
    url: 'hktv_koc/cms/vod',
    method: 'GET',
    params: data,
  });

// patch extra score
export const patchPostDataAPI = (data: UpdatePostDataParam[]) =>
  customAxios({
    url: `hktv_koc/cms/posts/`,
    method: 'PATCH',
    data,
  });

export const patchPostCategoryAPI = ({ postUuid, ...data }: PatchPostCategoryParam) =>
  customAxios({
    url: `hktv_koc/cms/categories/posts/${postUuid}`,
    method: 'PATCH',
    data,
  });

export const fetchPinnedLandingAPI = (mallCategory: string) =>
  customAxios({
    url: `hktv_koc/cms/posts/landings/${mallCategory}`,
    method: 'GET',
  });

export const updatePinnedLandingsAPI = (data: PinnedLaningRequest) =>
  customAxios({
    url: 'hktv_koc/cms/posts/landings',
    method: 'PATCH',
    data,
  });

export const fetchPinnedExplorePostsAPI = (category: string) =>
  customAxios({
    url: `hktv_koc/cms/posts/explore/${category}`,
    method: 'GET',
  });

export const updatePinnedExplorePostsAPI = (data: PinnedExplorePostRequest) =>
  customAxios({
    url: 'hktv_koc/cms/posts/explore',
    method: 'PATCH',
    data,
  });
