/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './productList.scss';

import { Avatar, List } from 'antd';

interface ProductListProps {
  productArray?: {
    skuCode: string;
    skuName: string;
    skuPackingSpec: string;
    skuImageUrl: string;
    disabled: boolean;
  }[];
  littleMallProductArray?: {
    skuNameCh: string;
    skuNameEn: string;
    mainPhoto: string;
    storeSkuId: string;
    disabled: boolean;
  }[];
  qcsProducts?: {
    code: string;
    imageUrl: string;
    name: string;
    disabled: boolean;
  }[];
}

const ProductList = ({
  productArray,
  littleMallProductArray,
  qcsProducts,
}: ProductListProps): JSX.Element => (
  <div className="product--root">
    {productArray?.length && (
      <List
        itemLayout="horizontal"
        dataSource={productArray}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar size={40} shape="square" src={item.skuImageUrl} />}
              title={item.skuName}
              description={item.skuPackingSpec}
              style={item.disabled ? { backgroundColor: '#fff1f0' } : {}}
            />
          </List.Item>
        )}
      />
    )}
    {littleMallProductArray?.length && (
      <List
        className="items-start"
        itemLayout="horizontal"
        dataSource={littleMallProductArray}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar size={40} shape="square" src={item.mainPhoto} />}
              title={item.skuNameCh}
              description={item.storeSkuId}
              style={item.disabled ? { backgroundColor: '#fff1f0' } : {}}
            />
          </List.Item>
        )}
      />
    )}
    {qcsProducts?.length && (
      <List
        className="items-start"
        itemLayout="horizontal"
        dataSource={qcsProducts}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar size={40} shape="square" src={item.imageUrl} />}
              title={item.name}
              description={item.code}
              style={item.disabled ? { backgroundColor: '#fff1f0' } : {}}
            />
          </List.Item>
        )}
      />
    )}
  </div>
);

export default ProductList;
