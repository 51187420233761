import {
  convertTimeObjectToMiliSecond,
  getTimeObjectFromMiliSecond,
  TimeObject,
} from '@/Utils/timeTranslate';
import { useAppDispatch } from '@/Hooks/useAppRedux';
import { Avatar, Button, Input, Modal, Radio, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { UpdatePostSkuParam } from '@/Interfaces/I_modal';
import { updatePostSku } from '@/Redux/slices/ModalSlice';
import SkuType from '@/Interfaces/I_Sku';
import { MenuOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext, 
  DragEndEvent, 
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import randomStringGenerator from '@/Utils/randomStringGenerator';
import SkuTimestampEditor from './SkuTimestampEditor';
import ConfirmationModal from './ConfirmationModal';

export interface PostSku {
  id: string;
  dndId: string;
  imageUrl: string;
  timestamp?: number[];
  timeObject?: TimeObject[];
  isNewlyAdded: boolean;
}

export interface PinnedSku {
  id: string;
  timestamp: number[];
  timeObject?: TimeObject[];
}

interface EditSkuModalProps {
  isModalVisible: boolean;
  setModalVisible: (value: boolean) => void;
  skuList: PostSku[];
  skuType: SkuType | null;
  timestampList: PinnedSku[];
  postUuid: string;
  postOwnerRole?: string;
  supportEditSkuTimestamp: boolean;
}

interface FooterProps {
  onSaveClick: () => void;
  onCancelClick: () => void;
}

const DEFAULT_TIME_OBJECT: TimeObject = { hour: '0', minute: '0', second: '0' };

const Footer = ({ onSaveClick, onCancelClick }: FooterProps) => (
  <div className="flex flex-row">
    <Button onClick={onSaveClick}>save</Button>
    <Button danger onClick={onCancelClick}>
      cancel
    </Button>
  </div>
);

interface PostSkuListProps {
  skuList: PostSku[];
  isSkuTransitioning: boolean;
  onSkuDeleteClick: (skuId: string) => void;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject) => void;
  onTimestampDeleteClick: (skuId: string, timestampIndex: number) => void;
  onAddTimestampClick: (skuId: string) => void;
  onSkuCodeChange: (skuIndex: number, skuCode: string) => void;
  supportEditSkuTimestamp: boolean;
  onDragStart: () => void;
  onDragEnd: (event: DragEndEvent) => void;
}

interface PostSkuListItemProps {
  sku: PostSku;
  skuIndex: number;
  isSkuTransitioning: boolean;
  onSkuDeleteClick: (skuId: string) => void;
  onTimeObjectChange: (skuIndex: number, timestampIndex: number, timeObject: TimeObject) => void;
  onTimestampDeleteClick: (skuId: string, timestampIndex: number) => void;
  onAddTimestampClick: (skuId: string) => void;
  onSkuCodeChange: (skuIndex: number, skuCode: string) => void;
  supportEditSkuTimestamp: boolean;
}

const PostSkuListItem = ({
  sku,
  skuIndex, 
  isSkuTransitioning, 
  onSkuDeleteClick,
  onTimeObjectChange,
  onTimestampDeleteClick,
  onAddTimestampClick,
  onSkuCodeChange,
  supportEditSkuTimestamp,
}: PostSkuListItemProps) => {
  const { 
    attributes,
    listeners,
    transform,
    transition,
    setNodeRef,
    setActivatorNodeRef,
  } = useSortable({ 
    id: sku.dndId,
    animateLayoutChanges: () => false, 
});
  
  const style = {
    backgroundColor: 'rgba(255, 255, 255, 0.5)', 
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition: isSkuTransitioning ? transition : undefined, 
  };

  return (
    <div className="postSkusList-sku-container pb-5 flex items-center gap-1" ref={setNodeRef} style={style}>
      <div className="flex flex-col pr-1"><MenuOutlined ref={setActivatorNodeRef} {...attributes} {...listeners}/></div>
      <div className="flex flex-col pr-1"><Avatar size={40} shape="square" src={sku.imageUrl} /></div>
      <div className="flex flex-col">
        <div className="flex flex-row justify-center items-center gap-2" style={{ width: "25.2rem" }}>
          <div className="postSkusList-skus-code font-bold w-80">
            {sku.isNewlyAdded ? (
              <Input
                className="w-70"
                placeholder="Enter SKU code"
                value={sku.id}
                onChange={(e) => onSkuCodeChange(skuIndex, e.target.value)}
                status={sku.id === '' ? 'error' : ''}
              />
            ) : (
              sku.id
            )}
          </div>
          <Button
            danger
            onClick={() => onSkuDeleteClick(sku.id)}
            disabled={!supportEditSkuTimestamp}
          >
            {' '}
            Delete SKU
          </Button>
        </div>
        <div className="timestamp-list">
          {sku.timeObject &&
            sku.timeObject.map((timeObject, timestampIndex) => (
              <div className="timestamp flex gap-1">
                <SkuTimestampEditor
                  timeObject={timeObject}
                  skuIndex={skuIndex}
                  timestampIndex={timestampIndex}
                  onTimeObjectChange={onTimeObjectChange}
                />
                <Button
                  className="ms-5"
                  type="default"
                  danger
                  onClick={() => onTimestampDeleteClick(sku.id, timestampIndex)}
                >
                  {' '}
                  -{' '}
                </Button>
              </div>
            ))}
          <Button
            className="text-sky-500 border-sky-500 mt-2"
            onClick={() => onAddTimestampClick(sku.id)}
            disabled={!supportEditSkuTimestamp}
          >
            Add Timestamp
          </Button>
        </div>
      </div>
    </div>
  ); 
}; 


const PostSkuList = ({
  skuList,
  isSkuTransitioning, 
  onSkuDeleteClick,
  onTimeObjectChange,
  onAddTimestampClick,
  onTimestampDeleteClick,
  onSkuCodeChange,
  supportEditSkuTimestamp,
  onDragStart,
  onDragEnd,
}: PostSkuListProps) => 
  {
  const sensors = useSensors(useSensor(PointerSensor));
  
  return (
    <div className="postSkusList-container">
      <DndContext 
        modifiers={[restrictToVerticalAxis]}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        collisionDetection={closestCenter}
        sensors={sensors}
      >
        <SortableContext 
          items={skuList.map((sku) => ({...sku, id: sku.dndId}))}
          strategy={verticalListSortingStrategy}
        >
          {skuList.map((sku, index) => (
            <PostSkuListItem 
              sku={sku}
              skuIndex={index}
              isSkuTransitioning={isSkuTransitioning}
              onSkuDeleteClick={onSkuDeleteClick}
              onTimeObjectChange={onTimeObjectChange}
              onAddTimestampClick={onAddTimestampClick}
              onTimestampDeleteClick={onTimestampDeleteClick}
              onSkuCodeChange={onSkuCodeChange}
              supportEditSkuTimestamp={supportEditSkuTimestamp}
            />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  ); 
};

const EditSkuModal = ({
  isModalVisible,
  setModalVisible,
  skuList,
  skuType,
  timestampList,
  postUuid,
  postOwnerRole,
  supportEditSkuTimestamp,
}: EditSkuModalProps) => {
  const dispatch = useAppDispatch();

  const [isDeleteSkuModalShow, setIsDeleteSkuModalShow] = useState(false);
  const [isSaveModalShow, setIsSaveModalShow] = useState(false);
  const [isSkuTransitioning, setIsSkuTransitioning] = useState(false);

  const [skus, setSkus] = useState<PostSku[]>(skuList);
  const [selectedSkuType, setSelectedSkuType] = useState<SkuType | null>(skuType);
  const [deleteSkuId, setDeleteSkuId] = useState<string | null>(null);

  const onSaveClick = () => {
    try {
      skus.forEach((sku) => {
        if (sku.id === '') {
          throw new TypeError('invalid data in Sku Code');
        }
        sku.timestamp?.forEach((timestamp) => {
          if (timestamp < 0 || timestamp === undefined || timestamp === null) {
            throw new TypeError('Invalid data in timestamp');
          }
        });
      });

      setIsSaveModalShow(true);
    } catch (e) {
      message.error({
        content: `${e}, please check if you have type the right value`,
        duration: 3,
        style: {
          marginTop: '120px',
        },
      });
    }
  };

  const onSaveConfirmClick = () => {
    if (selectedSkuType !== null) {
      const payload = {
        skuType: selectedSkuType,
        post_uuid: postUuid,
        skus: skus.map((sku) => ({
          skuCode: sku.id,
          timestamp: sku.timestamp || [],
        })),
      } as UpdatePostSkuParam;
      dispatch(updatePostSku(payload));
    }

    setIsSaveModalShow(false);
    setModalVisible(false);
  };


  const onAddSkuClick = () => {
    setSkus((prev) => [
      ...prev,
      {
        id: '',
        timestamp: [],
        timeObject: [],
        isNewlyAdded: true,
        dndId: randomStringGenerator(), 
      },
    ]);
  };

  const onSkuCodeChange = (skuIndex: number, updated: string) => {
    setSkus((prev) =>
      prev.map((sku, index) => {
        if (index === skuIndex) {
          return { ...sku, id: updated };
        }
        return sku;
      }),
    );
  };

  const onSkuDeleteClick = (skuId: string) => {
    setDeleteSkuId(skuId);
    setIsDeleteSkuModalShow(true);
  };

  const onSkuDeleteConfirmClick = () => {
    if (deleteSkuId !== null) {
      setSkus((curr) => curr.filter((sku) => sku.id !== deleteSkuId));
      setDeleteSkuId(null);
    }
    setIsDeleteSkuModalShow(false);
  };

  const onAddTimestampClick = (skuId: string) => {
    setSkus((curr) =>
      curr.map((sku) => {
        if (sku.id === skuId) {
          return {
            ...sku,
            timestamp: sku.timestamp ? [...sku.timestamp, 0] : [0],
            timeObject: sku.timeObject
              ? [...sku.timeObject, DEFAULT_TIME_OBJECT]
              : [DEFAULT_TIME_OBJECT],
          };
        }

        return sku;
      }),
    );
  };

  const onTimeObjectChange = (skuIndex: number, timestampIndex: number, timeObject: TimeObject) => {
    setSkus((curr) =>
      curr.map((sku, index) => {
        if (index === skuIndex) {
          return {
            ...sku,
            timestamp: sku.timestamp?.map((t, ti) => {
              if (ti === timestampIndex) {
                return convertTimeObjectToMiliSecond(timeObject);
              }
              return t;
            }),
            timeObject: sku.timeObject?.map((t, ti) => (ti === timestampIndex ? timeObject : t)),
          };
        }

        return sku;
      }),
    );
  };

  const onTimestampDeleteClick = (skuId: string, timestampIndex: number) => {
    setSkus((curr) =>
      curr.map((sku) => {
        if (sku.id === skuId) {
          return {
            ...sku,
            timestamp: sku.timestamp?.filter((_, index) => index !== timestampIndex),
            timeObject: sku.timeObject?.filter((_, index) => index !== timestampIndex),
          };
        }

        return sku;
      }),
    );
  };

  const onDragStart = () => {
    setIsSkuTransitioning(true);
  }

  const onDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;

    if (active.id !== over?.id) {
        const oldIndex = skus.findIndex((sku) => sku.dndId === active.id);
        const newIndex = skus.findIndex((sku) => sku.dndId === over?.id);
        const updated = arrayMove(skus, oldIndex, newIndex);
        setSkus(updated); 
        setIsSkuTransitioning(false); 
    }
  }

  const resetModal = () => {
    setSkus(skuList);
    setSelectedSkuType(skuType);
    setModalVisible(false);
  };

  useEffect(() => {
    setSelectedSkuType(() => {
      if (skuType === null) {
        if (postOwnerRole && ['OFFICIAL', 'MERCHANT'].includes(postOwnerRole)) {
          return SkuType.HKTVmall;
        }
        return SkuType.LittleMall;
      }

      return skuType;
    });
  }, [postOwnerRole, skuType]);

  useEffect(() => {
    setSkus(() =>
      skuList.map((sku) => {
        const timestamp = (timestampList || []).find((t) => t.id === sku.id)?.timestamp;
        return {
          ...sku,
          timestamp,
          timeObject: timestamp?.map((t) => getTimeObjectFromMiliSecond(t)),
          dndId: randomStringGenerator(),
        };
      }),
    );
  }, [skuList, timestampList]);

  return (
    <>
      <Modal
        title="Edit Post Sku"
        open={isModalVisible}
        footer={<Footer onSaveClick={onSaveClick} onCancelClick={resetModal} />}
        onCancel={resetModal}
      >
        <div className="editSkuModal-sku-type-picker w-full flex justify-center pb-5">
          <Radio.Group
            value={selectedSkuType}
            optionType="button"
            buttonStyle="solid"
            onChange={(e) => {
              setSelectedSkuType(e.target.value);
            }}
          >
            <Radio.Button
              value={SkuType.HKTVmall}
              disabled={skus.length > 0 || postOwnerRole === 'HKTV_SHOPS_MERCHANT'}
            >
              HKTV
            </Radio.Button>
            <Radio.Button
              value={SkuType.LittleMall}
              disabled={
                skus.length > 0 || postOwnerRole === 'OFFICIAL' || postOwnerRole === 'MERCHANT'
              }
            >
              Little Mall
            </Radio.Button>
            <Radio.Button
              value={SkuType.QCS}
              disabled={
                skus.length > 0 ||
                !postOwnerRole ||
                !['HKTV_SHOPS_MERCHANT', 'YOUTUBER', 'OFFICIAL', 'MERCHANT'].includes(postOwnerRole)
              }
            >
              QCS
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="editSkuModal-wrapper">
          <div className="editSkuModal-edit-list-header flex justify-between items-center pb-5">
            <p className="font-bold self-center m-0">SKU List</p>
            <Button
              className="editSkuModal-add-sku-button"
              type="primary"
              onClick={onAddSkuClick}
              disabled={!supportEditSkuTimestamp}
            >
              Add SKU
            </Button>
          </div>
          <PostSkuList
            skuList={skus}
            isSkuTransitioning={isSkuTransitioning}
            onSkuDeleteClick={onSkuDeleteClick}
            onTimeObjectChange={onTimeObjectChange}
            onAddTimestampClick={onAddTimestampClick}
            onTimestampDeleteClick={onTimestampDeleteClick}
            onSkuCodeChange={onSkuCodeChange}
            supportEditSkuTimestamp={supportEditSkuTimestamp}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
          />
        </div>
      </Modal>
      <ConfirmationModal
        title={<p>Confirmation</p>}
        body={<p>Are you sure to save?</p>}
        onConfirm={() => onSaveConfirmClick()}
        setConfirmationModalShow={setIsSaveModalShow}
        isModalVisible={isSaveModalShow}
      />
      <ConfirmationModal
        title={<p>Confirmation</p>}
        body={<p>Are you sure to delete SKU?</p>}
        onConfirm={() => onSkuDeleteConfirmClick()}
        setConfirmationModalShow={setIsDeleteSkuModalShow}
        isModalVisible={isDeleteSkuModalShow}
      />
    </>
  );
};

export default EditSkuModal;
